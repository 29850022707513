
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Home",
  computed: {
    ...mapGetters({
      profile: "authorisation/profile",
    }),
  },
});
